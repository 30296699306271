import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { LogoWrapper } from "./LogoWrapper";

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const logos = [ 
  { src: "bnetza.png" }, 
  { src: "bits.png" },
  { src: "aok.svg" }, 
  { src: "brk.svg" },
];
export default function Example() {
  const [showImpressum, setShowImpressum] = useState(false);

  return (
    <>
      <Transition.Root show={showImpressum} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowImpressum(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 pb-20 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div
                    className={classNames(
                      "rounded-t sm:px-6 md:px-0 sm:pb-4 overflow-scroll bg-white"
                    )}
                  >
                    <div className="sm:flex sm:items-start">
                      <div className="text-center sm:mt-0 sm:text-left w-full">
                        <div className="px-6 mt-2 w-full max-h-[100vh] lg:overflow-x-scroll lg:max-h-[60vh] 2xl:max-h-[80vh]">
                          <h2 className="font-bold ">Impressum</h2>
                          <br />

                          <div className="content_box ">
                            <div className="">
                              <h2>Angaben gemäß § 5 TMG</h2>
                              <br />
                              <p>ShowLab GmbH</p>
                              <p>Bahnhofstraße 5</p>
                              <p>83646 Bad Tölz</p>
                              <p>&nbsp;</p>
                              <p>
                                <span>
                                  Geschäftsführer: Jannik Straube, Maximilian
                                  Mayr
                                </span>
                                <br />
                                <span>Amtsgericht München, HRB 278762</span>
                              </p>
                              <p>
                                <span></span>
                              </p>
                              <p>
                                <span>Steuernummer: 139/137/30717</span>
                                <br />
                                <span>USt-Identifikationsnr.: DE355411056</span>
                              </p>
                              <p>&nbsp;</p>
                              <h2>Kontakt</h2>
                              <p>E-Mail: kontakt@show-lab.de</p>
                              <h2>EU-Streitschlichtung</h2>
                              <p>
                                Die Europäische Kommission stellt eine Plattform
                                zur Online-Streitbeilegung (OS) bereit:
                                <span>&nbsp;</span>
                                <a
                                  href="https://ec.europa.eu/consumers/odr/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://ec.europa.eu/consumers/odr/
                                </a>
                                <br />
                                Unsere E-Mail-Adresse finden Sie oben im
                                Impressum.
                              </p>
                              <h2>
                                Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
                              </h2>
                              <p>
                                Wir sind nicht bereit oder verpflichtet, an
                                Streitbeilegungsverfahren vor einer
                                Verbraucherschlichtungsstelle teilzunehmen.
                              </p>
                              <p>
                                Quelle:<span>&nbsp;</span>
                                <a href="https://www.e-recht24.de/">
                                  e-recht24.de
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="bg-slate-800 min-h-screen flex flex-col">
        <div className="mx-auto space-y-4 md:space-y-0 max-w-7xl sm:mt-24 sm:px-6 lg:px-32 bg-slate-800 w-full flex flex-col md:flex-row justify-between">
          <img src="sl_logo.webp" className="p-4 md:p-0 md:h-6 md:w-auto" />
          <div className="text-slate-600 font-bold text-md flex flex-row items-center rounded py-2 px-4 bg-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            08041 4488391
          </div>
        </div>
        <div className="mx-auto max-w-7xl sm:mt-12 sm:px-6 lg:px-8 bg-slate-800">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-16">
            <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white">
            Innovator in Softwareentwicklung, Eventautomation und Veranstaltungstechnik
            </h2> 
            <h3 className="mx-auto max-w-2xl text-xl font-medium text-white">Maßgeschneiderte Lösungen Showproduktionen und Tourneen</h3>


            <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-300">
             Bei uns verschmelzen Spitzenleistungen in Softwareentwicklung, Eventautomation und Veranstaltungstechnik, um technische Herausforderungen zu meistern und innovative Lösungen zu schaffen.<br />
            Unser multidisziplinäres Team aus Softwareentwicklern, Automationsingenieuren und Veranstaltungstechnikern setzt Maßstäbe in Deutschland und Europa.<br /><br />
            Egal, ob es um die Optimierung von Tourneen oder die Realisierung von Showproduktionen geht, wir bieten individuelle Lösungen, die die Performance und Effizienz unserer Kunden maximieren.<br /><br />
              <h4 className="mx-auto max-w-2xl text-md font-bold tracking-tight text-white">
                <b>Kontaktieren Sie uns</b> telefonisch unter{" "}
                <span className="font-mono">08041 4488391</span> oder per E-Mail
                an kontakt [at] show-lab.de
                <br />
                <br />
                Wir freuen uns auf das Gespräch mit Ihnen!
              </h4>
            </p>
            <div className="mx-auto mt-20 grid max-w-2xl items-center gap-12 sm:max-w-xl grid-cols-2 sm:gap-x-14 sm:gap-y-14 lg:max-w-4xl lg:grid-cols-4">
              {logos.map((logo) => (
                <LogoWrapper src={logo.src} key={logo.src} />
              ))}
            </div>
            <div
              className="absolute -top-24 right-0 -z-10 transform-gpu blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1404/767] w-[87.75rem] bg-gradient-to-r from-[#80caff] to-[#007e59] opacity-25"
                style={{
                  clipPath:
                    "polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)",
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center py-6 text-gray-200">
          <div
            className="cursor-pointer"
            onClick={() => setShowImpressum(true)}
          >
            Impressum
          </div>
        </div>
      </div>
    </>
  );
}
